<template>
  <section class="call_details">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card>
          <b-card-title>
            <b-row>
              <b-col md="3">
                <div class="d-flex align-items-center">
                  <h3 class="page-title mb-n2">Histórico de Atividades</h3>
                  <p class="mt-2 mb-n1 ml-3 text-muted">
                    <!-- {{ totalRows }} -->
                    {{ totalRows | numberFilter }}
                  </p>
                </div>
              </b-col>

              <button
                class="btn btn-primary call_details_btn_update"
                @click="updateHistory(ctx)"
              >
                Atualizar
              </button>
            </b-row>
          </b-card-title>

          <b-table
            ref="selectableTable"
            show-empty
            stacked="md"
            :items="search"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filtros"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            responsive
            :busy="loadingTable"
            v-if="forceRender"
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>


            




            <template #cell(created_at)="row">
              <span class="text-wrap">
                {{ row.item.created_at | formatDateTime }}
              </span>
            </template>

            <template #cell(twilio_duration)="row">
              <span class="text-wrap" v-if="row.item.id != 4">
                {{ row.item.twilio_duration | formatForMinutes }}
                <!-- {{ row.item.twilio_duration  }} -->
              </span>
            </template>

            <template #cell(notas)="row">
              <!-- hide_text123 -->
              <div class="text-wrap hide_text">
                {{ row.item.notas }}
                <!-- <span class="text-wrap" v-if="row.item.id !=4">
              </span> -->
              </div>
            </template>

            <template #cell(acoes)="row"
              ><!--row-->
              <!-- <button class="btn mr-1 btn-outline-primary">Detalhes</button> -->

              <b-button  @click="showModal(row)" variant="primary" id="toggle-btn"
                >Detalhes</b-button
              >
              <!-- {{ row.item }} -->
            </template>
          </b-table>

          <b-row class="justify-content-md-center">
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="md"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <b-modal
      centered
      header-text-variant="light"
      header-class="headerModal"
      :content-class="
        !this.$store.state.showWhiteMode
          ? 'modallEditarStatus'
          : 'modallEditarStatusWhite'
      "
      hide-footer
      scrollable
      v-model="modalShow"
    >
      <!-- body-bg-variant="light" -->

      <template #modal-title>
        <span class="text-primary font-weight-bold">Detalhes</span> da Chamada
      </template>

      <div v-if="details_cal">
        <h6 class="text-muted">
          <span class="text-primary">Autor: </span>
          {{ details_cal.creator.nome }}
        </h6>
        <h6 class="text-muted">
          <span class="text-primary">Para: </span> {{ details_cal.twilio_to }}
        </h6>
        <h6 class="text-muted">
          <span class="text-primary">Data: </span>
          {{ details_cal.created_at | formatDateTime }}
        </h6>
        <h6 class="text-muted">
          <span class="text-primary">Duração: </span>
          {{ details_cal.twilio_duration | formatForMinutes }}
        </h6>
        <h6 class="text-muted">
          <span class="text-primary">Descricao: </span>
          {{ details_cal.descricao }}
        </h6>
        <h6 class="text-muted">
          <span class="text-primary">Notas:</span> {{ details_cal.notas }}
        </h6>
        <h6 class="text-muted" v-if="details_cal.chamada_status">
          <span class="text-primary">Status: </span>
          {{ details_cal.chamada_status.descricao }}
        </h6>
        <h6 class="text-muted">
          <span class="text-primary">Sid da Chamada: </span>
          {{ details_cal.twilio_sid }}
        </h6>

        <h6 class="text-muted">
          <span class="text-primary">twilio_start_time: </span>
          {{ details_cal.twilio_start_time | formatDateTime }}
        </h6>
        <h6 class="text-muted">
          <span class="text-primary">twilio_end_time: </span>
          {{ details_cal.twilio_end_time }}
        </h6>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";

// import ProjetoSelect from "@/components/ProjetoSelect.vue";
// import LeadService from "@/services/lead.service";
import LeadStatusService from "@/services/leadstatus.service";
import LeadatividadesService from "@/services/leadatividades.service.js";

export default {
  name: "Leads",

  components: {
    // ProjetoSelect,
  },
  data() {
    return {
      fields: [
        {
          key: "creator.nome",
          sortable: false,
          label: "Nome",
        },

        {
          key: "twilio_to",
          sortable: false,
          label: "Para",
        },
        {
          key: "created_at",
          sortable: false,
          label: "Data",
        },

        {
          key: "twilio_duration",
          sortable: false,
          label: "Duração",
        },

        {
          key: "descricao",
          sortable: false,
          label: "Descrição",
        },

        {
          key: "notas",
          sortable: false,
          label: "Notas",
        },

        {
          key: "chamada_status.descricao",
          sortable: false,
          label: "Status",
        },

        {
          key: "acoes",
          sortable: false,
          label: "Ações",
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      loadingTable: false,
      exporting: false,
      filtros: { projeto: null, lead_status: null },
      leadStatus: [],
      ctx: undefined,
      forceRender: true,
      // details_cal: {
      //   sid: 123456789,
      // },
      details_cal: undefined,
      modalShow: false,
    };
  },
  computed: {
    ...mapState(["projetoSelecionado"]),
  },
  created() {
    this.loadFilters();
  },
  mounted() {
    // console.log(this.details_cal);
  },
  watch: {
    projetoSelecionado: function () {
      this.filtros.projeto = this.projetoSelecionado;
      this.loadFilters();
    },
  },
  methods: {
    ...mapMutations(["setCall", "setPhone"]),

    search(ctx) {
      // console.log("chamado search ",ctx.filter.projeto.id);

      // console.log(this.$route.params.id);
      if (!ctx.filter.projeto.id) {
        return [];
      }
      this.ctx = ctx;
      const params = {
        // projeto_id: ctx.filter.projeto ? ctx.filter.projeto.id : null,
        // lead_status_id: ctx.filter.lead_status
        //   ? ctx.filter.lead_status.id
        //   : null,
        page: ctx.currentPage,
        perPage: ctx.perPage,
        // perPage: 5,
        orderBy: ctx.sortBy,
        // sort: ctx.sortDesc ? "desc" : "asc",
        sort: "desc",

        lead_id: this.$route.params.id,
      };

      this.loadingTable = true;

      // const promise = LeadatividadesService.get(params, this.$route.params.id);
      const promise = LeadatividadesService.getAll(
        params,
        this.$route.params.id
      );

      return promise
        .then((response) => {
          // console.log(response.data);

          this.loadingTable = false;
          const items = response.data.data;
          this.totalRows = response.data.total;
          return items || [];
        })
        .catch((e) => {
          console.log(e);
          this.loadingTable = false;
          this.totalRows = 0;
        });
    },

    loadFilters() {
      this.filtros.lead_status = null;
      //Carregar Status do Projeto

      if (this.projetoSelecionado) {
        LeadStatusService.getAll({
          projeto_id: this.projetoSelecionado.id,
        }).then((response) => {
          // console.log(response.data);
          // console.log(this.projetoSelecionado.id);

          this.leadStatus = response.data;
        });
      }

      this.filtros.projeto = this.projetoSelecionado;
    },

    async atividades() {
      const res = await LeadatividadesService.get();

      console.log(res.data);
    },

    showModal(row) {
      this.modalShow = true;
      

      this.details_cal = row.item;

      // console.log(this.details_cal);
    },
    updateHistory() {
      this.forceRender = false
       this.search(this.ctx);

      setTimeout(() => {
        this.forceRender = true;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.hide_text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 180px;
}
.call_details {
  .call_details_btn_update {
    position: absolute;
    right: 60px;
  }
}
</style>
