<template>
  <section id="leadView">
    <div v-if="loading" class="circle-loader"></div>
    <!-- Main content -->

    
    <div class="content" v-if="contato && lead">
      <b-row>
        <b-col sm="12" md="8">
          <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
              <b-card>
                <template #header>
                  <div class="d-lg-flex justify-content-between">
                    <h6 class="mb-0">
                      <i class="fa fa-info mr-1"></i> Detalhes do Contato
                    </h6>
                    <router-link
                      :to="{ name: 'EditContato', params: { id: contato.id } }"
                      class="btn btn-secondary"
                    >
                      <i class="mdi mdi-pencil mr-2"></i>
                      Editar Contato
                    </router-link>
                  </div>
                </template>
                <b-card-body>
                  <b-row>
                    <b-col cols="4" v-if="contato.nome">
                      <dl>
                        <dt>Nome</dt>
                        <dd>{{ contato.nome }}</dd>
                      </dl>
                    </b-col>
                    <b-col cols="4">
                      <dl>
                        <dt>Empresa</dt>
                        <dd>
                          <span class="text-wrap">
                            <router-link
                              v-if="contato.empresa"
                              :to="{
                                name: 'EmpresaView',
                                params: { id: contato.empresa.id },
                              }"
                            >
                              <template v-if="contato.empresa.nome_fantasia">
                                {{ contato.empresa.nome_fantasia }}
                              </template>
                              <template
                                v-else-if="contato.empresa.empresa_base"
                              >
                                {{ contato.empresa.empresa_base.razao_social }}
                              </template>
                              <template v-else>
                                {{ contato.empresa_cnpj | cnpjFilter }}
                              </template>
                            </router-link>
                            <template v-else-if="contato.empresa_cnpj">
                              {{ contato.empresa_cnpj | cnpjFilter }}
                            </template>
                            <template v-else-if="contato.empresa_nome">
                              {{ contato.empresa_nome }}
                            </template>
                          </span>
                        </dd>
                      </dl>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4">
                      <dl>
                        <dt><i class="fa fa-address-card mr-1"></i> Cargo</dt>
                        <dd>{{ contato.cargo }}</dd>
                      </dl>
                    </b-col>
                    <b-col cols="4">
                      <dl>
                        <dt>
                          <i class="fa fa-university mr-1"></i> Departamento
                        </dt>
                        <dd v-if="contato.departamento">
                          <template >
                            {{ contato.departamento.nome }}
                          </template>
                        </dd>
                      </dl>
                    </b-col>
                    <b-col cols="4">
                      <dl>
                        <dt>
                          <i class="fa fa-sitemap mr-1"></i> Nível Hierárquico
                        </dt>
                        <dd>
                          <template v-if="contato.nivel_hierarquico">
                            {{ contato.nivel_hierarquico.nome }}
                          </template>
                        </dd>
                      </dl>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </div>
          </div>

          <b-row>
            <div class="col-lg-6 grid-margin stretch-card">
              <b-card>
                <b-card-title>
                  <h6 class="mb-0">
                    <i class="fa fa-phone mr-1"></i> Telefones
                  </h6>
                </b-card-title>

                <table class="table">
                  <tbody class="contato__view__color--white">
                    <tr
                      v-for="telefone in contato.contato_telefones"
                      :key="telefone.id"
                    >
                      <td class="text-muted">
                        {{ telefone.telefone }}
                      </td>

                      <td class="text-muted">
                        <i
                          v-if="telefone.valido"
                          class="icon-check text-success"
                          v-b-tooltip.hover
                          title="Válido"
                        ></i>

                        <i
                          v-else-if="telefone.valido === 0"
                          class="icon-info text-danger"
                          v-b-tooltip.hover
                          title="Inválido"
                        ></i>

                        <i
                          v-else
                          class="icon-info text-warning"
                          v-b-tooltip.hover
                          title="Validação Pendente"
                        ></i>
                      </td>

                      <td class="text-right">
                        <button
                          class="btn btn-success mr-2"
                          v-on:click="call(telefone)"
                          v-b-tooltip.hover.top="'Clique para Ligar'"
                        >
                          <i class="icon-phone"></i>
                        </button>

                        <button
                          class="btn btn-success"
                          v-b-tooltip.hover.top="'Enviar Mensagem por WhatsApp'"
                          v-b-modal.modal-send-whatsapp
                          @click="setWhatsapp(telefone)"
                        >
                          <i class="mdi mdi-whatsapp"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card>
            </div>
            <div class="col-lg-6 grid-margin stretch-card">
              <b-card>
                <b-card-title>
                  <h6 class="mb-0">
                    <i class="fa fa-envelope mr-1"></i> Emails
                  </h6>
                </b-card-title>

                <!-- <b-list-group flush>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    v-for="email in contato.contato_emails"
                    :key="email.id"
                    variant="info"
                  >
                    <span>{{ email.email }}</span>
                    <b-badge v-if="email.valido" variant="success"
                      >Válido</b-badge
                    >
                    <b-badge v-else-if="email.valido === 0" variant="danger"
                      >Inválido</b-badge
                    >
                    <b-badge v-else variant="outline-dark"
                      >Validação Pendente</b-badge
                    >
                  </b-list-group-item>
                </b-list-group> -->

                <b-list-group flush>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    v-for="email in contato.contato_emails"
                    :key="email.id"
                    variant="info"
                  >
                    <span class="text-muted">{{ email.email }}</span>

                    <b-badge v-if="email.valido" variant="success"
                      >Válido</b-badge
                    >

                    <i
                      v-if="email.valido"
                      class="icon-info text-success"
                      v-b-tooltip.hover
                      title="Válido"
                    ></i>

                    <!-- <b-badge v-else-if="email.valido === 0" variant="danger"
                      >Inválido</b-badge
                    > -->

                    <i
                      v-else-if="email.valido === 0"
                      class="icon-info text-danger"
                      v-b-tooltip.hover
                      title="Inválido"
                    ></i>

                    <!-- <b-badge v-else variant="outline-warning"
                      >Validação Pendente</b-badge
                    > -->
                    <i
                      v-else
                      class="icon-info text-warning"
                      v-b-tooltip.hover
                      title="Validação Pendente"
                    ></i>

                    <!-- <button>aa</button> -->

                    <a
                      :href="`mailto:${email.email}`"
                      v-b-tooltip.hover
                      title="Enviar email"
                      class="btn btn-link"
                    >
                      <i class="mdi mdi-email-outline"></i>
                    </a>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </div>
          </b-row>
        </b-col>
        <b-col sm="12" md="4">
          <div class="row h-100">
            <div class="col-lg-12 grid-margin stretch-card">
              <b-card>
                <template #header>
                  <h6 class="mb-0">
                    <i class="mdi mdi-phone mr-1"></i> Qualificação
                  </h6>
                </template>

                <div v-if="lead.lead_status">
                  <!-- <h6>{{lead.lead_status.nome}}</h6> -->

                  <div class="status">
                    <h6 class="white-mode__black-bold">Status</h6>

                    <span
                      :class="
                        lead.lead_status.lead_status_tipo_id == 1
                          ? 'text-success'
                          : lead.lead_status.lead_status_tipo_id == 2
                          ? 'text-danger'
                          : 'text-muted'
                      "
                      >{{ lead.lead_status.nome }}</span
                    >
                    <hr />
                  </div>

                  <div
                    class="motivo__recusa"
                    v-if="lead.lead_status.lead_status_tipo_id == 2"
                    
                  >
                    <h6 class="white-mode__black-bold">Motivo:</h6>

                    <span class="text-muted" v-if="lead.lead_recusa ">{{ lead.lead_recusa.nome }}</span>

                    <hr />
                  </div>

                  <div class="notas">
                    <h6 class="white-mode__black-bold">Notas:</h6>

                    <span class="text-muted">{{ lead.notas }}</span>
                    <hr />
                  </div>

                  <div
                    class="recusas"
                    v-if="lead.lead_status.lead_status_tipo_id == 2"
                  >
                    <h6 class="white-mode__black-bold">Notas da Recusa:</h6>

                    <span class="text-muted">{{ lead.recusa_notas }}</span>

                    <hr />
                  </div>

                  <div class="">
                    <div class="d-flex">
                      <div class="dot-indicator bg-primary mt-1 mr-2"></div>
                      <div class="d-flex flex-column">
                        <p class="mb-0 text-muted">Tentativas</p>
                        <small class="text-gray">{{ lead.tentativas }}</small>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>

                <div class="d-flex w-100 justify-content-center">
                  <div
                    class="w-100 text-center"
                    v-if="lead.lead_status === null"
                  >
                    <h6 class="text-warning">Lead ainda não qualificado</h6>
                    <b-button
                      variant="success"
                      class="w-100"
                      @click="setModalQualificacao"
                    >
                      Iniciar Qualificação
                    </b-button>
                  </div>

                  <div v-else class="w-100">
                    <!-- <p class="text-warning">Atualizar Qualificação</p> -->
                    <b-button
                      variant="primary"
                      class="w-100"
                      @click="setModalQualificacao"
                    >
                      Atualizar Qualificação
                    </b-button>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </b-col>
      </b-row>

      <CallDetails />

      <ModalQualificacao
        v-if="showModalQualificacao"
        :fetchDataView="fetchData"
      />

      <ModalNovaOportunidade v-if="showModalNovaOportunidade" />

      <WhatsAppSender
        v-if="false"
        :contatoLead="contato"
        :selectedTelefone="selectedTelefone"
      />
    </div>

  </section>
  <!-- </div> -->
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LeadService from "../../services/lead.service";
import LeadStatusService from "../../services/leadstatus.service";
import LeadRecusaService from "../../services/leadrecusa.service";
import ContatoService from "../../services/contato.service";
import CallDetails from "./components/CallDetails.vue";
import WhatsAppSender from "./components/WhatsAppSender.vue";
import ModalQualificacao from "./components/ModalQualificacao.vue";
import ModalNovaOportunidade from "./components/ModalNovaOportunidade.vue";

export default {
  name: "LeadView",
  props: ["id"],
  components: {
    CallDetails,
    WhatsAppSender,
    ModalQualificacao,
    ModalNovaOportunidade,
  },
  data() {
    return {
      lead: null,
      contato: null,
      loading: false,
      // message: "",
      emailFields: ["nome", "email", { key: "valido", label: "Status" }],
      // template: [],
      options: [],
      leadStatus: [],
      leadRecusas: [],
      selectedTelefone: null,
    };
  },
  computed: {
    ...mapState(["projetoSelecionado"]),
    ...mapState({
      showModalQualificacao: (state) => state.leads.showModalQualificacao,
      showModalNovaOportunidade: (state) => state.leads.showModalNovaOportunidade,
    }),
  },
  created() {
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",

    
  },
  methods: {
    ...mapMutations([
      "setCall",
      "setPhone",
      "setShowModalQualificacao",
      "setIdLead",
      "setContatoData",
    ]),

    fetchData() {
      this.lead = null;
      this.loading = true;

      // Carregar dados do Lead
      LeadService.get(this.id)
        .then((response) => {
          this.lead = response.data;

          // console.log(this.lead);

          this.contato = null;

          // console.log(response.data);

          // Carregar dados do Contato
          ContatoService.get(this.lead.contato_id).then((response) => {
            // console.log(response.data);
            this.contato = response.data;
          });

          //Carregar Status do Projeto
          let params = {};
          if (this.projetoSelecionado) {
            params.projeto_id = this.projetoSelecionado.id;
          } else if (this.lead.projeto_id) {
            params.projeto_id = this.lead.projeto_id;
          } else {
            this.$router.push({ name: "Leads" });
          }
          LeadStatusService.getAll(params).then((response) => {
            this.leadStatus = response.data;
          });

          //Carregar Motivos de Recusa do Projeto
          LeadRecusaService.getAll(params).then((response) => {
            this.leadRecusas = response.data;
          });
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    saveOrUpdate() {
      this.loading = true;

      if (!this.lead.lead_status) {
        this.loading = false;
        return;
      }

      this.lead.lead_status_id = this.lead.lead_status
        ? this.lead.lead_status.id
        : null;
      this.lead.lead_recusa_id = this.lead.lead_recusa
        ? this.lead.lead_recusa?.id
        : null;

      console.log(this.lead.lead_recusa_id);

      const promise = LeadService.saveOrUpdate(this.lead);
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });

          this.fetchData();
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },

    call(telefone) {
      //alert(fone)
      // console.log(telefone);

      // console.log(this.contato);

      this.setCall(true);
      // this.setPhone(number.slice(2));
      this.setPhone({
        numero: telefone.telefone,
        lead_id: this.id,
        contato_telefone_id: telefone.id,
        contato_destino: this.contato,
      });
    },

    setWhatsapp(telefone) {
      this.selectedTelefone = telefone;
    },

    setModalQualificacao() {
      this.setIdLead(this.id); // global
      this.setContatoData(this.contato); // global
      this.setShowModalQualificacao(true); // global
    },
  },

  mounted() {},
};
</script>


<style lang="scss" >
#sidebar-for-send-whatsapp {
  width: 40%;
  background: #191c24 !important;
  box-shadow: 0 0 35px -3px #000;
  border-top-left-radius: 6px;
  transition: 0.4s;

  textarea {
    height: 250px;
  }
}
</style>


<style lang="scss" scoped>
.form-control:disabled,
.form-control[readonly] {
  background: transparent;
}

.list-group-item-info,
.contato__view__color--white {
  background-color: transparent;
  /* color: #fff; */
}

#notasRecusaInput,
#notasInput {
  overflow-y: auto !important;
}
</style>