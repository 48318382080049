<template>
  <b-modal
    id="modal-send-whatsapp"
    lazy
    :static="true"
    centered
    header-text-variant="light"
    no-close-on-backdrop
    header-class="headerModal"
    content-class="modall"
    hide-footer
    scrollable
    v-model="modalShow"
  >
    <template #modal-title>
      <span>Enviar Mensagem WhatsApp</span> <i class="mdi mdi-whatsapp"></i>
      <br />
      <div class="text-warning">
        <span>{{ currentProject.telefone.description || "" }}</span> -
        <span>{{ currentProject.telefone.telefone || "" }}</span>
      </div>
    </template>

    <form
      @submit.prevent="sendMessageWhatsapp"
      class="d-flex flex-column h-100"
    >
      <!-- <h2 v-for="item in selectedRows " :key="item.id">la {{item.telefone}}</h2> -->

      <div v-if="contato">
        <div class="form-group">
          <label class="d-block">Nome</label>
          <div>
            <input
              type="text"
              placeholder="Name"
              class="form-control"
              :value="contato.nome"
              disabled
            />
          </div>
        </div>

        <div class="form-group" v-if="selectedTelefone">
          <label class="d-block">whatsapp do cliente</label>
          <div>
            <input
              type="text"
              placeholder="Name"
              class="form-control"
              :value="selectedTelefone.telefone"
              disabled
            />
          </div>
        </div>

        <div class="form-group">
          <label class="d-block">Formato internacional:</label>
          <div>
            <!-- <input
              type="text"
              placeholder="Name"
              class="form-control"
              :value="selectedTelefone.telefone "
              disabled
            /> -->

            <TheMask
              v-model="newNumber"
              type="text"
              :mask="['+############', '+#############', '+###############']"
              class="form-control"
              style="flex: 1"
              required
              v-b-tooltip.hover.right
              title="Formato internacional: +5511999999999"
            />
          </div>
        </div>
      </div>

      <div class="form-group"> 
        <b-form-select v-model="selected" :options="options"></b-form-select>
        <!-- <h2>{{selected}}</h2> -->
      </div>

      <div class="form-group" v-if="selected">
        <div class="mt-2" >
          <div class="form-inline">
            <!-- <b-form-select
              id="inline-form-custom-select-pref"
              class="mb-2 mr-sm-2 mb-sm-0 "
              style="min-height: auto"
              
              :value="5"
            ></b-form-select> -->

            <div
              class="mb-1 d-flex w-100"
              v-for="(field, index) in selected.fields"
              :key="index"
            >
              <!-- <h1>{{field}}</h1> -->
              <b-form-input
                style="width: 55px"
                class="mb-2 mr-sm-2 mb-sm-0"
                :value="`{{${field.name}}}`"
                disabled
                required
              ></b-form-input>
              <!-- <h1>{{field}}</h1> -->
              <!-- <input type="text" value="1" readonly> -->

              <b-form-input
                id="inline-form-input-name"
                class="mb-2 mr-sm-2 mb-sm-0 flex-grow-1"
                :placeholder="field.placeholder"
                v-model.trim="field.value"
                required
              ></b-form-input>

              <b-dropdown
                size="sm"
                text="Left align"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <i class="fa fa-search"></i>
                  <!-- &#x1f50d; -->

                  <span class="sr-only">Search</span>
                </template>
                <b-dropdown-item
                  href="#"
                  v-for="(item, i) in utilsOptions"
                  :key="i"
                  @click="() => (selected.fields[index].value = item)"
                  >{{ item }}</b-dropdown-item
                >
                <!-- <b-dropdown-item href="#">Another action</b-dropdown-item>
                <b-dropdown-item href="#">Something else here...</b-dropdown-item> -->
              </b-dropdown>
            </div>
          </div>
        </div>

        <!-- <button class="btn mt-3" @click="setInftemplate">
          <i class="icon-plus"></i>
        </button> -->
      </div>

      <div class="p-3 flex-grow-1">
        <!-- <p v-if="selected" class="text-muted">{{ selected.body }}</p> -->
        <p v-if="selected" class="text-muteds">{{ selectedTemplate }}</p>
      </div>

      <!-- <button class="btn btn-success" @click="teste">set</button> -->

      <div class="text-right">
        <!-- <button class="btn btn-success" @click="sendMessageWhatsapp"> -->
        <button class="btn btn-success" :disabled="!selectedTemplate">
          <template v-if="loading">
            <b-spinner small type="grow"></b-spinner>
            Enviando...
          </template>
          <template v-else> Enviar </template>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { TheMask } from "vue-the-mask";

import TwilioService from "@/services/twilio.service";
import axios from "axios";
import observableStateChat from "../../../store/module-observable/conversation";

export default {
  props: ["contatoLead", "selectedTelefone"],
  components: { TheMask },
  data() {
    return {
      modalShow: false,
      contato: undefined,
      selected: null,
      options: [{ value: null, text: "Selecione um template" }],
      numero: "",
      newNumber: undefined,
      utilsOptions: [],
      selectedTemplate: undefined,
      loading: false,
      // infoProject: undefined
    };
  },

  mounted() {
    // console.log("contatoLead", this.contatoLead);
    this.contato = this.contatoLead;

    this.getTemplates();
    this.getUtilsOptions();

    // this.infoProject = this.currentProject

    // console.log(this.currentProject);
    // currentProject.currentProjectInfo
  },
  methods: {
    getTemplates() {
      TwilioService.getTemplatesWhatsApp()
        // axios.get("http://localhost:5555/")

        .then((res) => {
          // console.log(res.data);
          this.options = [...this.options, ...res.data];
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async sendMessageWhatsapp() {
      const isValid = this.validateFields();
      if (!isValid) return;
      this.newNumber = this.newNumber.replace("+", "");

      this.loading = true;
      try {
        const res = await axios.post(
          "https://start-conversation-by-template-5984-dev.twil.io/sendMessage",
          {
            whats_client: this.newNumber,
            // whats_client: "5511971970000",
            // whats_client: "558287086083",
            // whats_twilio: "5511998009970",
            whats_twilio: this.currentProject.telefone.telefone,
            friendly_name: this.contatoLead.nome || "",
            body_message: this.selectedTemplate,
          }
        );

        // console.log(res.data);
        this.makeToast("success", res.data);

        this.selectedTemplate = undefined;
        this.selected = null;
        this.modalShow = false;
        this.loading = false;
      } catch (error) {
        //   console.log("erro aqiu");
        console.log(error);
        this.loading = false;
      }
    },

    makeToast(variant = null, data) {
      this.$bvToast.toast(
        `${this.contatoLead.nome} - ${data?.conversation?.participantMessagingBinding?.address}`,
        {
          title: `Mensagem Enviada`,
          variant: variant,
          solid: true,
        }
      );
    },

    formatNumber() {
      if (
        (this.numero[0] == 5 && this.numero[1] == 5) ||
        (this.numero[0] == "+" && this.numero[1] == 5 && this.numero[2] == 5)
      ) {
        return (this.newNumber = this.numero);
      }

      //   console.log(this);
      return (this.newNumber = "55" + this.numero);
    },
    getUtilsOptions() {
      // console.log(this.contatoLead);

      if (this.contatoLead) {
        const { nome, cargo } = this.contatoLead;
        // console.log(nome.split(" ")[0]);
        this.utilsOptions = [nome.split(" ")[0], nome, cargo];
      }
    },
    changeTemplate() {
      this.selectedTemplate = this.selected?.body;

      const val = this.selected?.fields || [];

      val.forEach((item, index) => {
        // console.log(index + 1);

        // console.log(item.value);
        if (!item.value) return;

        if (item.name == index + 1) {
          this.selectedTemplate = this.selectedTemplate.replace(
            `{{${index + 1}}}`,
            item.value
          );
        }
      });
    },

    validateFields() {
      if (!this.newNumber) return false;

      const fields = this.selected.fields;

      const isValid = fields.every((item) => {
        return item.value != "";
      });

      if (!isValid) return false;
      if (!this.currentProject.telefone.telefone) return false;

      return true;
    },
  },
  computed: {
    currentProject() {
      return observableStateChat.currentProjectInfo;
    },
  },

  watch: {
    contatoLead() {
      console.log("selectedRows", this.contatoLead);
      this.contato = this.contatoLead;
    },
    selectedTelefone() {
      // console.log("selectedTelefone ", this.selectedTelefone);
      this.numero = this.selectedTelefone?.telefone;

      this.formatNumber();
    },
    selected() {
      // console.log("selected ", this.selected);
      this.selectedTemplate = this.selected?.body;
      // console.log(this.selectedTemplate);
    },

    "selected.fields": {
      handler() {
        this.changeTemplate();
      },
      deep: true,
    },

    options() {
      // console.log("options", this.options);
    },
  },
};
</script>

<style lang="scss">
#modal-send-whatsapp {
  /* max-height: 70vh;
    
    overflow-y: scroll; */

  .modall {
    background: #191c24 !important;
    min-height: 370px;
  }

  /* .headerModal {
  } */
}
</style>


<style >
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}
</style>